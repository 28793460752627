.arrayProductCard {
  padding-top: 15px;
}

.bgProductCard {
  z-index: 2;
  overflow: hidden;
  display: block;
  border: 3px solid $bgColor6;
  border-radius: 5%;
  border-bottom-right-radius: 20%;
  border-top-left-radius: 20%;
  height: 300px;
  margin: 0 15px 30px 20px;
  padding: 10px;
  box-shadow: 0 0 2px white;
  transition: border 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.bgColorHover {
  cursor: pointer;
  border: 3px solid $bgColor5;
  // transform: scale(1.05);
  box-shadow: 3px 7px 7px rgba(0, 0, 0, 0.212);
}

.productCard {
  height: 100%;
  position: relative;
  z-index: 4;

  .textProductCard {
    position: absolute;
    margin-top: 20px;
    padding-right: 10px;
    bottom: 0;
    height: 92px;

    h1 {
      padding-bottom: 5px;
    }

    h2 {
      padding-top: 10px;
      padding-bottom: 10px;
      color: black;
      &:visited {
        color: black;
      }
    }
  }

  img {
    // position: absolute;
    display: block;
    height: 200px;
    padding-top: 10px;
    margin: auto;
    margin-bottom: 10px;
  }
}
