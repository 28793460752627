body {
  background-color: $bgColor7;
  letter-spacing: 0.6px;
}

.textColor {
  button {
    color: $bgColor5 !important;
  }
}
