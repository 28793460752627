// Color

$bgColor1: #f8fafa;
$bgColor2: #747474;
$bgColor3: #fff;

// Background

$bgColor4: #fdf9d6;
$bgColor5: #46c0b9;
$bgColor6: #f2c04c;
$bgColor7: white;
$bgColor11: #fffeea;
$bgColor10: #6ebb5d;

.bgWhite {
  background-color: $bgColor7;
  transform: translateZ(0);
}

// Gradient

$linear-gradient-1: linear-gradient(90deg, $bgColor6, $bgColor5);

//Shadow

$bgColor8: #b1d45a85;
$bgColor9: #b1d45a91;

//position

#iconMenu,
.positionFixed {
  position: fixed;
}

#bgLogo,
.contact,
.contentTitleInSummary,
.btn,
footer,
.home,
#leafBottomLeft,
#leafTopRight,
.legalArticle p,
main,
.productList,
.bgProductCard {
  position: relative;
}

.all-menu-navbar,
.btn-navbar:before,
.btn-extLink:before,
.contact:before,
#leafTopRightImg,
.legalArticle p:before,
.logo-upps,
.logoPictureFooter,
#leafBottomLeftImg {
  position: absolute;
}

// Padding

.padding-left {
  padding-left: 300px;
}

.lineBottom {
  background: linear-gradient(to right, transparent, $bgColor5, transparent);
  height: 3px;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.textColor {
  color: $bgColor5;
}

.marginLeft15px {
  margin-left: 15px;
}

.overflowHidden {
  overflow: hidden;
}

a,
.cursor,
label {
  cursor: pointer !important;
}

a:active,
a:focus,
a:hover,
a:visited {
  color: black;
}

.z-index {
  z-index: 10;
}
