.search-bar {
  height: 50px;
}

.search-label {
  height: 30px;
  padding-left: 10px;
  padding-bottom: 5px;
  background-color: transparent;
  border: none;
  border-bottom: transparent;
  border-right: transparent;
  transition: all 0.5s;

  &:focus {
    outline: none;
    // background: linear-gradient(to right, white, transparent, transparent);
    border-bottom: 3px solid $bgColor5;
    border-right: 3px solid $bgColor5;
    color: $bgColor2;
    // transform: translateX(-50%);
  }
}

.btn-search {
  border: none;
  background: transparent;
}
