@font-face {
  font-family: "Farro";
  src: url("../../../public/fonts/Farro-Medium.ttf") format("truetype");
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: "Farro";
  src: url("../../../public/fonts/Farro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
