footer {
  &:before {
    content: "";
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    height: 200px;
    border-bottom: 3px solid $bgColor6;
    background-color: $bgColor7;
    border-radius: 50% / 100%;
  }

  .logoPictureFooter {
    top: 0;
    left: 50%;
    height: 250px;
    transform: translate(-50%, -50%);
    z-index: 5;
  }

  .footerContainer {
    height: 100%;
    margin: 0 20px;
  }

  .socialNetwork {
    height: 35px;
    border-radius: 5px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;

    margin: 0 5px;
    background-color: white;
    z-index: 2;
    border: 3px solid $bgColor6;
    transition: border 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
      transform 0.2s ease-in-out;
    transform: scale(1);

    svg {
      margin: auto;
      width: 25px;
      height: 25px;
      fill: $bgColor6;
      transition: fill 0.2s ease-in-out;
    }

    &:hover {
      cursor: pointer;
      border: 3px solid $bgColor5;
      box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.212);
      transform: scale(1.2);

      svg {
        fill: $bgColor5;
      }
    }
  }

  .legal {
    color: white;
    font-size: 15px;

    .legalArticle {
      p {
        font-size: 12px;
        margin: 15px 0;
        padding: 5px 10px 5px 10px;
      }
    }
  }

  .contact {
    font-size: 20px;
    color: white;
  }

  .contact,
  .legalArticle p {
    &:before {
      content: "";
      bottom: -5px;
      left: 50%;
      width: 0;
      height: 2px;
      background-color: white;
      transition: all 0.2s;
      transform: translateX(-50%);
    }

    &:hover:before {
      width: 80%;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1199px) {
  footer {
    height: 500px;

    .footerContainer {
      max-height: 300px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .containerLegal,
  .legal {
    height: 100%;
  }

  footer {
    height: 350px;
  }
}
