.globalLegalContainer {
  li,
  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.08rem;
    color: $bgColor2;
  }

  li {
    padding: 15px 0 0 0;

    &:before {
      content: "•";
      color: $bgColor5;
      font-weight: 700;
      display: inline-block;
      width: 2rem;
      margin-left: -1em;
      font-size: 2rem;
      vertical-align: middle;
    }
  }
}

.legalTitle {
  text-transform: uppercase;
  font-weight: 700;
  background-color: $bgColor11;
}

.subLegalTitle {
  // font-size: 1.3rem;
  font-weight: 700;
  color: $bgColor2;
}

// @media screen and (max-width: 1199px) {
//   .legalContainer {
//     text-align: justify;
//   }
// }
