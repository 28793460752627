// ------ WIDTH ------ //

.allNavBar,
.bgProductDetails img,
.globalLegalContainer,
.globalLegalContainer ul,
footer,
.footerContainer,
#leafBottomLeft,
#leafTopRight,
.legalTitle,
.lineBottom,
// .logoPictureFooter svg,
.navbar,
.productCard,
.productTab,
.search-bar,
.search-label:focus,
.subLegalTitle,
.textProductCard,
.youWillLikeIt {
  width: 100%;
}

.bgProductDetails,
.bgProductDetails img,
.imgYouWillLikeIt,
.navbar img {
  width: auto;
}

.line {
  width: 2px;
}

.search-label {
  width: 15px;
}

.socialNetwork {
  width: 35px;
}

.contactContainer,
.socialNetWorkContainer {
  width: 240px;
}

.logoPictureFooter {
  width: 250px;
}

.bgProductCard {
  width: 300px;
}

.legal {
  width: 400px;
}

.blocTitleImg {
  width: 80vw;
}

#leaf {
  width: 100vw;
}

#leafBottomLeftImg img,
#leafTopRightImg img {
  width: calc(100% - 10px);
}

// ------ MAX-WIDTH ------ //

.globalLegalContainer ul {
  max-width: 600px;
}

.bgProductDetails img,
.legalContainer {
  max-width: 800px;
}

.footerContainer {
  max-width: 900px;
}

.arrayProductCard {
  max-width: 1100px;
}

.bgParagraph {
  max-width: 1800px;
}

// ------ MEDIA QUERIES ------ //

@media screen and (max-width: 400px) {
  .bubleInSummary {
    width: 98%;
  }
}

@media screen and (min-width: 401px) {
  .bubleInSummary {
    width: 400px;
  }
}

// @media screen and (max-width: 500px) {
//   .menu-navbar {
//     width: 180px;
//   }
// }

@media screen and (max-width: 1199px) {
  .bgParagraph {
    picture {
      width: 100%;
    }
  }

  .legalContainer {
    width: 95%;
  }
}

@media screen and (min-width: 1200px) {
  .imgProduit {
    width: 1000px;
  }

  .titleTextProduct {
    width: 800px;
  }

  .youWillLikeIt-content {
    max-width: 1350px;
  }

  .youWillLikeIt-content,
  .legalContainer {
    width: 100%;
  }
}
