.btn {
  border: transparent;
  background-color: transparent;
}

.btn-navbar {
  margin: 0 10px 0 10px;
}

.btn-extLink {
  transition: all 0.2s;

  &:hover {
    transition: all 0.2s;
    color: black;
    // cursor: pointer;
  }
}

.btn-navbar,
.btn-extLink {
  padding-left: 5px;
  padding-right: 5px;
  &:before {
    content: "";
    bottom: -5px;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: $bgColor5;
    transition: all 0.2s;
    transform: translateX(-50%);
  }

  &:hover:before {
    width: 100%;
  }
}

.selected {
  &:before {
    width: 100%;
  }
}
