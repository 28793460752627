#root {
  font-family: "Farro", sans-serif;
}

.titleTextProduct h2,
.subCategory,
.productCard h1,
.productCard h2,
.btn-search,
.search-label,
.btn-navbar {
  font-weight: bold;
}

.titleTextProduct h1 {
  font-weight: 700;
}

#root {
  font-weight: lighter;
  font-size: 0.8rem;
}

//      FONT SIZE      //

.subCategory h1,
.productCard h1,
.search-label {
  font-size: 1.1rem;
}

.textInSummary,
.textProductCard p {
  font-size: 0.9rem;
}

.subCategory,
.btn-navbar {
  font-size: 1rem;
}

.titleInSummary,
.btn-search {
  font-size: 2rem;
}

.bgParagraph h3 {
  font-size: 2rem;
}

.legalTitle {
  font-size: calc(1rem + 1.6vw);
}

.subLegalTitle {
  font-size: calc(0.8rem + 1.5vw);
}

.titleTextProduct {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 1199px) {
  .bgParagraph h3 {
    font-size: calc(0.5rem + 1.5vw);
  }

  .textParagraph {
    font-size: calc(0.4rem + 1.5vw);
  }

  .titleTextProduct {
    h1 {
      font-size: calc(0.5rem + 2.5vw);
    }
    h2 {
      font-size: calc(0.5rem + 2vw);
    }
  }
}

@media screen and (min-width: 1200px) {
  .textParagraph {
    font-size: calc(0.2rem + 0.8vw);
  }
}
