#bgLogo {
  min-height: 100vh;

  #leaf {
    display: grid;
    height: 100vh;
    grid-template-columns: repeat(2, 1fr);

    #leafBottomLeft,
    #leafTopRight {
      height: 100%;
    }
  }

  #leafTopRightImg {
    right: -10px;
    top: 0;
  }

  #leafBottomLeftImg {
    bottom: -2px;
  }
  .logo-upps {
    width: calc(300px + 10vw);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.svg-group-1 {
  color: $bgColor5;
}

.svg-group-2 {
  color: $bgColor6;
}
