.all-menu-navbar,
.allNavBar,
.arrayProductCard,
.bgParagraph,
.bgProductDetails,
.blocTitleImg,
.category,
.contactContainer,
.containerLegal,
footer,
.footerContainer,
.globalLegalContainer,
.imgProduit,
.legal,
.legalArticle,
.legalContainer,
.legalTitle,
.logo,
main,
.menu-navbar,
.menu-navbar .a,
.menuUser,
.productDescriptPresentFirst,
.productTab,
.search-bar,
.socialNetwork,
.socialNetWorkContainer,
.textProductCard,
.titleTextProduct h1,
.youWillLikeIt,
.youWillLikeIt-content {
  display: flex;
}

//------ FLEX-DIRECTION --------//

.bgParagraph,
.contactContainer,
.containerLegal,
.globalLegalContainer,
.legal,
.legalContainer,
main,
.youWillLikeIt,
.textProductCard {
  flex-direction: column;
}

.arrayProductCard,
.bgProductDetails,
footer,
.socialNetwork,
.socialNetWorkContainer {
  flex-direction: row;
}

//------ JUSTIFY-CONTENT --------//

.arrayProductCard,
footer,
.legal {
  justify-content: space-around;
}

.containerLegal,
.menuUser,
.search-bar,
.socialNetWorkContainer {
  justify-content: flex-end;
}

.logo {
  justify-content: flex-start;
}

.bgProductDetails,
.menu-navbar {
  justify-content: space-between;
}

.globalLegalContainer,
.legalContainer,
.legalTitle,
.youWillLikeIt {
  justify-content: center;
}

//------ ALIGN-CONTENT --------//

.arrayProductCard {
  align-content: baseline;
}

//------ ALIGN-ITEMS --------//

.bgProductDetails,
.blocTitleImg,
.containerLegal,
footer,
.globalLegalContainer,
.legalContainer,
.legalTitle,
main,
.productDescriptPresentFirst,
.search-bar,
.youWillLikeIt-content,
.menu-navbar {
  align-items: center;
}

.contactContainer,
.logo {
  align-items: flex-start;
}

//------ OTHER FLEX --------//

.arrayProductCard {
  flex-wrap: wrap;
}

.productDescriptPresentFirst {
  flex-grow: 1;
}

// .productTab,
// .youWillLikeIt-content {
//   flex-flow: row wrap;
// }

//------ MEDIA QUERIES --------//

@media screen and (max-width: 1199px) {
  .bgParagraph picture,
  .navbar {
    display: flex;
  }

  .productTab {
    justify-content: space-around;
  }

  .bgParagraph,
  .bgParagraph picture,
  .youWillLikeIt-content {
    justify-content: center;
  }

  .blocTitleImg,
  footer,
  .footerContainer,
  .menu-navbar,
  .navbar {
    flex-direction: column;
  }

  .productTab,
  .youWillLikeIt-content,
  .productDescriptPresentFirst {
    flex-flow: row wrap;
  }

  .blocTitleImg,
  .contactContainer,
  .footerContainer,
  .navbar {
    align-items: center;
  }

  .blocTitleImg,
  .socialNetWorkContainer {
    justify-content: center;
  }

  .footerContainer {
    justify-content: end;
  }

  .legal {
    order: 3;
  }
}

@media screen and (min-width: 1200px) {
  .all-menu-navbar {
    flex-direction: column;
    align-items: flex-end;
  }

  .blocTitleImg,
  .category,
  .footerContainer,
  .legalArticle,
  .menu-navbar {
    flex-direction: row;
  }

  .footerContainer,
  .legalArticle {
    justify-content: space-between;
  }

  .contactContainer {
    align-items: flex-start;
  }

  .footerContainer,
  .legalArticle,
  .socialNetwork {
    align-items: center;
  }

  .socialNetwork {
    justify-content: center;
  }

  .socialNetWorkContainer {
    justify-content: flex-end;
  }
}
