.bgParagraph {
  h3 {
    text-transform: uppercase;
  }

  .productDescriptPresentFirst {
    padding-bottom: 15px;
  }

  .productDescriptPresentNext {
    padding-bottom: 15px;
  }

  .productDescriptPresentNext:first-child {
    padding-bottom: -50px;
    margin-top: 15px;
  }
}

.bgProductDetails {
  img {
    height: 100%;
    object-fit: cover;
  }
}

.titleTextProduct {
  h2 {
    margin: 0;
  }
}

.imgYouWillLikeIt {
  object-fit: cover;

  &:hover {
    cursor: pointer;
  }
}

.blockBubleInSummary {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: row wrap;
  margin-top: 50px;
  min-width: 80%;
  max-width: 1200px;
  margin-bottom: 25px;

  .bubleInSummary {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 10px 50px 10px;
    overflow: hidden;
    border-radius: 15px;
    border-bottom-right-radius: 80px;
    border-top-left-radius: 80px;
    border-width: 2px;
    border-style: solid;
    box-shadow: 1px 5px 5px 5px rgba(0, 0, 0, 0.068);

    .textInSummary {
      margin-top: 20px;
      padding: 20px 25px 20px 15px;

      h5,
      h6 {
        padding-bottom: 10px;
      }

      li {
        padding-left: 15px;
        padding-bottom: 5px;
        margin-bottom: 15px;
      }
    }

    .bubleInSummary,
    .titleInSummary {
      text-align: center;
    }

    .titleInSummary {
      font-size: 1.5rem;
      font-weight: 700;
      padding: 30px 20px 20px 20px;
      text-transform: uppercase;
    }
  }

  .contentTitleInSummary,
  .textInSummary {
    z-index: 3;
  }

  .contentTitleInSummary {
    width: 100%;
    height: 150px;
  }

  .bgRadiantInSummary,
  .bgInSummary {
    z-index: -1;
    position: absolute;
  }

  .bgRadiantInSummary {
    width: 100%;
    height: 100%;
    bottom: 25px;
  }

  .bgInSummary {
    height: 150%;
    transform: rotate(-14deg) translate(20px, -30%);
    width: 440px;
    top: -50px;
    left: -50px;
  }
}

//--------- MEDIA QUERIES --------//

@media screen and (min-width: 1200px) {
  .bgParagraph {
    h3 {
      padding: 20px 0 0 100px;
      letter-spacing: 2px;
    }
  }

  .bgProductDetails {
    margin-top: 125px;
  }

  .imgProduit {
    margin: -60px 50px 30px 0;
    height: auto;
    background-position: center;
    background-size: 350px;
  }

  .textParagraph {
    letter-spacing: 1.5px;
    padding: 10px 80px 0 80px;
  }

  .titleTextProduct {
    margin-left: -50px;
    margin-right: 5rem;
    height: 100%;
    margin-left: -50px;
    border-bottom-right-radius: 40px;
    position: relative;
    margin-right: calc(1rem + 2vw);
  }

  .youWillLikeIt {
    margin-top: 25px;

    .youWillLikeIt-content {
      margin-left: 50px;
      margin-top: 15px;
      margin-bottom: 35px;
      height: 300px;
    }
  }

  .imgYouWillLikeIt {
    height: 300px;
  }
}

@media screen and (max-width: 1199px) {
  .productTab {
    li {
      margin-bottom: 15px;
    }
  }
}

@media screen and (max-width: 1199px) {
  .productTab {
    li {
      margin-bottom: 30px;
    }
  }

  .bgParagraph {
    h3 {
      padding: 20px 20px 0 40px;
    }
  }

  .imgYouWillLikeIt {
    margin-top: -20px;
  }

  .textParagraph {
    margin: 10px 40px;
    text-align: justify;
  }

  .youWillLikeIt-content .textParagraph {
    margin-bottom: 40px;
  }
}
