//            MARGIN              //

.logoPictureFooter {
  margin: auto;
}

//          MARGIN-LEFT           //

.logo {
  margin-left: 20px;
}
//          MARGIN-RIGHT          //

.all-menu-navbar,
.allNavBar {
  margin-right: 20px;
}

//          MARGIN-TOP           //

.socialNetwork {
  margin-top: 20px;
}

footer {
  margin-top: 180px !important;
}

//         MARGIN-BOTTOM          //

.contact,
.legalContainer ul,
.legalTitle {
  margin-bottom: 20px;
}

@media screen and (max-width: 1199px) {
  .socialNetWorkContainer {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .legal {
    margin-top: 20px;
  }

  .containerLegal {
    margin: 20px;
  }
}
